<template>
    <div id="app" class="widget" :class="themeClass">
        <div class="container">
            <h2 class="widget__title">Подписка на автомобиль</h2>
            <p class="widget__text">Подписка на автомобиль - это выгоднее, чем классическая аренда или покупка нового
                автомобиля. Если часто
                пользуетесь автомобилем в личных целях, но не хотите приобретать собственный по разным причинам, а
                вторичный рынок не интересует, то аренда автомобиля по подписке отличный выбор.</p>
            <div class="swiper__btns">
                <div class="swiper__btn-prev">
                    <svg class="icon icon-left">
                        <use xlink:href="~@/assets/sprite.svg#left"></use>
                    </svg>
                </div>
                <div class="swiper__btn-next">
                    <svg class="icon icon-right">
                        <use xlink:href="~@/assets/sprite.svg#right"></use>
                    </svg>
                </div>
            </div>
            <div class="widget__list swiper">
                <div class="swiper-wrapper">
                    <div class="widget__item swiper-slide" v-for="(item, index) in items" :key="index">
                        <div class="widget__item-wrap">
                            <div class="widget__img">
                                <img :src="item.img"/>
                            </div>
                            <div class="widget__item-content">

                                <div class="widget__top">
                                    <div class="widget__model">{{ item.model }}</div>
                                    <div class="widget__descr">
                                        <div>Сутки: от {{ item.day }} ₽/день</div>
                                        <div>Месяц: от {{ item.month }} ₽/день</div>
                                        <div>Год: от {{ item.year }} ₽/день</div>
                                        <div>3 года: от {{ item.years }} ₽/день</div>
                                    </div>
                                </div>
                                <div class="widget__bottom">
                                    <div class="widget__bottom-flex">
                                        <div class="tag-wrapper">
                                            <span class="tag tag--white">{{ item.type1 }}</span>
                                            <span class="tag tag--white">{{ item.type2 }}</span>
                                            <span class="tag tag--white">{{ item.type3 }}</span>
                                        </div>
                                        <div class="car-vin">
                                            {{ item.vin }}
                                        </div>
                                    </div>
                                    <div class="widget__btns">
                                        <button class="widget__btn"
                                                @click="onRun"
										><span>оставить заявку на подписку</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <transition name="dialog">
                    <div v-if="isDialogVisible" class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container">
                                <button
                                    type="button"
                                    class="modal-close"
                                    @click="onDialogClose"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px"
                                         viewBox="0 0 72 72"
                                         id="emoji">
                                        <g id="color"/>
                                        <g id="hair"/>
                                        <g id="skin"/>
                                        <g id="skin-shadow"/>
                                        <g id="line">
                                            <line x1="17.5" x2="54.5" y1="17.5" y2="54.5" fill="none" stroke="#000000"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                                  stroke-width="2"/>
                                            <line x1="54.5" x2="17.5" y1="17.5" y2="54.5" fill="none" stroke="#000000"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                                  stroke-width="2"/>
                                        </g>
                                    </svg>
                                </button>
                                <div>
                                    <h3 class="modal-header">
                                        Подписка на автомобиль
                                    </h3>

                                    <form @submit.prevent="submit">
                                        <input v-model="form.name" type="text" placeholder="ФИО">
                                        <input v-model="form.phone"
                                               @keydown="filterPhoneKey"
                                               @keyup="fixPhone"
                                               hide-details
                                               placeholder="Телефон"/>
                                        <p v-if="error" v-html="error"></p>
                                        <button type="submit" class="modal-submit">
                                            <pulse-loader v-if="isLoading" :loading="true" color="#fff" size="5px"/>
                                            <span v-else>Записаться</span>
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </transition>

            </div>

            <div class="white-section">
                <div class="heading-flex">
                    <h2 class="h2">Порядок оказания услуги</h2>
                </div>
                <div class="steps-banner">
                    <div class="steps-banner__left">
                        <div class="steps-banner__pic">
							<svg class="steps-banner__pic-elem" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M16 29.9375H62.875M16 29.9375V60.1875C16 60.7398 16.4477 61.1875 17 61.1875H61.875C62.4273 61.1875 62.875 60.7398 62.875 60.1875V29.9375M16 29.9375L38.376 45.6068C38.7144 45.8438 39.1637 45.8482 39.5068 45.618L62.875 29.9375" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<circle cx="62.8761" cy="61.1866" r="12.1515" fill="white" stroke="#ED1C24" stroke-width="1.5"/>
								<path d="M56.4102 61.77L61.4732 66.673L69.3418 57.3828" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M53.4375 12H24.4375C23.8852 12 23.4375 12.4477 23.4375 13V34.2383L38.8447 45.0152C38.9124 45.0625 39.0022 45.0634 39.0708 45.0174L54.4375 34.7031V13C54.4375 12.4477 53.9898 12 53.4375 12Z" fill="#ED1C24"/>
								<path d="M30.4375 25.0195H47.4375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M30.4375 33.0195H47.4375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M30.3477 17.8867H46.999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
                        </div>
                        <div class="steps-banner__text">
                            <div class="steps-banner__text-top">1 этап</div>
                            <div class="steps-banner__text-bot">Отправьте заявку и ожидайте приглашения</div>
                        </div>
                    </div>
                    <div class="steps-banner__right">
                        <div class="steps-banner__pic">
							<svg class="steps-banner__pic-elem" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M10.584 36.8469L15.0281 26.7717C15.864 24.5028 17.9951 22.9997 20.3758 23H42.9189C45.2991 22.9993 47.4297 24.5027 48.2641 26.7717L52.615 36.6817" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M7.99609 54.5742V58.0276C7.99609 59.7105 9.70059 61.0748 11.8032 61.0748C13.9058 61.0748 15.6103 59.7105 15.6103 58.0276V54.9805" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M53.8779 37.6922C52.5092 36.5664 52.9145 36.5664 50.6851 36.5664H12.6142C8.40899 36.5664 5 40.0363 5 44.3166V50.1292C5 52.2693 6.70449 54.0043 8.80709 54.0043C8.80709 54.0043 22.2663 54.0043 35.7567 54.0043" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M16.0674 45.5357C16.0674 46.5067 15.2802 47.294 14.3091 47.294C13.338 47.294 12.5508 46.5067 12.5508 45.5357C12.5508 44.5646 13.338 43.7773 14.3091 43.7773C15.2802 43.7773 16.0674 44.5646 16.0674 45.5357Z" stroke="black" stroke-width="1.5"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M68.043 35.375C62.5201 35.375 58.043 33.1924 58.043 30.5C58.043 27.8076 62.5201 25.625 68.043 25.625C73.5658 25.625 78.043 27.8076 78.043 30.5C78.043 33.1924 73.5658 35.375 68.043 35.375Z" fill="#ED1C24" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M58.043 35.375C58.043 38.0676 62.5196 40.25 68.043 40.25C73.5663 40.25 78.043 38.0676 78.043 35.375" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M58.043 40.25V30.5" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M78.043 30.5V54.875C78.043 57.5676 73.5663 59.75 68.043 59.75C66.7837 59.7519 65.5274 59.6305 64.293 59.3876" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M78.043 50C78.043 52.6926 73.5663 54.875 68.043 54.875C66.7837 54.8769 65.5274 54.7555 64.293 54.5126" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M78.043 45.125C78.043 47.8176 73.5663 50 68.043 50C66.7837 50.0019 65.5274 49.8805 64.293 49.6376" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M78.043 40.25C78.043 42.9426 73.5663 45.125 68.043 45.125C66.7837 45.1269 65.5274 45.0055 64.293 44.7626" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M50.543 52.4375C45.0201 52.4375 40.543 50.2549 40.543 47.5625C40.543 44.8701 45.0201 42.6875 50.543 42.6875C56.0658 42.6875 60.543 44.8701 60.543 47.5625C60.543 50.2549 56.0658 52.4375 50.543 52.4375Z" fill="#ED1C24" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M60.543 47.5625V57.3125C60.543 60.0051 56.0663 62.1875 50.543 62.1875C45.0196 62.1875 40.543 60.0051 40.543 57.3125V47.5625" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M40.543 52.4375C40.543 55.1301 45.0196 57.3125 50.543 57.3125C56.0663 57.3125 60.543 55.1301 60.543 52.4375" stroke="#ED1C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
                        </div>
                        <div class="steps-banner__text">
                            <div class="steps-banner__text-top">2 этап</div>
                            <div class="steps-banner__text-bot">Приезжайте в дилерский центр, внесите оплату и получите
                                авто
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {Swiper, Navigation} from 'swiper';
import 'swiper/swiper.scss';
import axios from "axios";
import Config from "@/config";
import PulseLoader from 'vue-spinner/src/PulseLoader';

import VueMask from 'v-mask' ;

Vue.use(VueMask);

let swiper;

export default {
    components: {PulseLoader},
    data() {
        return {
            isModalOpened: false,
            items: [
                {
                    id: 1,
                    model: "Kia RIO",
                    img: require('@/assets/1.png'),
                    day: "2 700",
                    month: "2 193",
                    year: "1 364",
                    years: "1 188",
                    type1: "Технологичный",
                    type2: "Для города",
                    type3: "Седан",
                    vin: "Седан",

                },
                {
                    id: 2,
                    img: require('@/assets/2.png'),
                    model: "Kia SELTOS",
                    day: "3 600",
                    month: "2 875",
                    year: "1 635",
                    years: "1 458",
                    type1: "Комфортный",
                    type2: "Для путешествий",
                    type3: "Внедорожник",
                    vin: "Внедорожник",
                },
                {
                    id: 3,
                    img: require('@/assets/3.png'),
                    model: "Kia SPORTAGE",
                    day: "3 800",
                    month: "3 220",
                    year: "1 885",
                    years: "1 695",
                    type1: "Семейный",
                    type2: "Внедорожник",
                    type3: "Популярный",
                    vin: "кроссовер",
                },
                {
                    id: 4,
                    img: require('@/assets/4.png'),
                    model: "Kia K5",
                    day: "4 100",
                    month: "3 520",
                    year: "1 987",
                    years: "1 809",
                    type1: "Для города",
                    type2: "Седан",
                    type3: "Популярный",
                    vin: "Седан",
                },
                {
                    id: 5,
                    img: require('@/assets/5.png'),
                    model: "Kia SORENTO",
                    day: "4 800",
                    month: "4 148",
                    year: "2 621",
                    years: "2 400",
                    type1: "Для путешествий",
                    type2: "Внедорожник",
                    type3: "Популярный",
                    vin: "кроссовер",
                },
                {
                    id: 6,
                    img: require('@/assets/6.png'),
                    model: "Kia Carnival",
                    day: "5 600",
                    month: "4 948",
                    year: "2 716",
                    years: "2 492",
                    type1: "Новинка",
                    type2: "Для путешествий",
                    type3: "Минивэн",
                    vin: "Минивэн",
                },
            ],
            //filteredItems: null,
            isDialogVisible: false,
            isSubmitted: false,
            form: {
                name: "",
                phone: "",
                theme: "",
            },
            error: "",
            isLoading: false
        }
    },
    computed: {
        themeClass() {
            const c = [];
            c.push("finished");
            if (this.$route.query?.theme) c.push(this.$route.query?.theme);
            return c;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },

        onRun() {
            this.isDialogVisible = true;
        },

        onDialogClose() {
            this.isDialogVisible = false;
        },

        initWidget() {
            // init theme
            this.form.theme = this.$route.query?.theme;
            // init theme items
            //const ids = this.$route.query?.ids?.split(',');
            //this.filteredItems = this.items.filter(item => ids?.some(id => +id === item.id));
        },

        submit() {
            const name = /^.{2,}$/;
            if (!name.test(String(this.form.name).toLowerCase())) {
                return this.error = "Пожалуйста, введите Ваше имя";
            }

            const phone = this.form.phone.replace(/\D+/g, "");
            //console.log("Phone: "+phone);
            const rePhone = /^\d{10,24}$/;
            if (!rePhone.test(phone)) {
                return this.error = "Пожалуйста, введите Ваш номер телефона";
            }

            this.error = false;
            this.isLoading = true;

            return axios.post(Config.FORM_URL, this.form)
                .then(res => {
                    if (res.error) {
                        console.error("ERROR", res);
                        if (res.data?.error) {
                            if (res.data.code === Config.ERROR_CODE_EXISTS) this.error = "Такой номер телефона уже зарегистрирован.<br />Регистрация возможна только один раз."
                            else if (res.data.code === Config.ERROR_CODE_WRONG_PARAMS) this.error = "Отправлены неверные параметры.<br />Пожалуйста, проверьте и попробуйте снова."
                            else this.error = "Произошла неизвестная ошибка.<br />Пожалуйста, попробуйте отправить еще раз.";

                        }
                    } else {
                        this.isSubmitted = true;
                    }
                })
                .catch(error => {
                    this.error = error;
                    console.error("ERROR", error);
                })
                .finally(res => {
                    this.isLoading = false;
                    this.isDialogVisible = false;
                });
        },
        filterPhoneKey(e) {
            //console.log(e);
            const a = e.key;

            // ignore arrows, del, backspace
            if (
                e.keyCode === 37 ||
                e.keyCode === 39 ||
                e.keyCode === 8 ||
                e.keyCode === 46 ||
                e.keyCode === 35 ||
                e.keyCode === 36 ||
                e.keyCode === 9 ||
                (e.key === "x" && e.ctrlKey) ||
                (e.key === "c" && e.ctrlKey) ||
                (e.key === "v" && e.ctrlKey)
            )
                return true;

            if (!this.validatePhoneKey(a)) e.preventDefault();
        },
        validatePhoneKey(a) {
            if (!a.match(/[+0-9\(\) -]/)) return false;

            let l = this.form.phone?.length;
            //console.log("a: "+a+", l: "+l);
            if (l === 0) {
                if (!a.match(/[+0-9]/)) return false;
                if (a === "+") this.form.phone = "";
                else if (a !== "8") this.form.phone = "+";
                else if (a === "9") this.form.phone = "+7 (";
            } else if (l === 1) {
                if (this.form.phone === "+" && a === "8") this.form.phone = "";
            }
            return true;
        },
        fixPhone(e) {
            if (
                e &&
                (e.keyCode === 37 ||
                    e.keyCode === 39 ||
                    e.keyCode === 8 ||
                    e.keyCode === 46 ||
                    e.keyCode === 35 ||
                    e.keyCode === 36)
            )
                return true;

            let s = this.form.phone;
            s = s.replace(/[^0-9]+/g, "");
            //console.log("s=" + s);
            //v = v.substr(1);

            this.phoneMaxlength = 32;
            if (!s.length) return "";
            const f = [];
            for (let i = 0; i < s.length; i++) {
                let l = s[i];
                //if ( i === 0 && l === "+" )
                //console.log(i + "=" + l);

                if (s[0] === "8") {
                    // 8 ...
                    this.phoneMaxlength = 17;
                    if (i === 1) {
                        f.push(" ");
                        f.push("(");
                    } else if (i === 4) {
                        f.push(")");
                        f.push(" ");
                    } else if (i === 7) {
                        f.push("-");
                    } else if (i === 9) {
                        f.push("-");
                    }
                    f.push(l);
                } else if (s[0] === "7") {
                    // +7 ...
                    this.phoneMaxlength = 18;
                    if (i === 0) {
                        f.push("+");
                    } else if (i === 1) {
                        f.push(" ");
                        f.push("(");
                    } else if (i === 4) {
                        f.push(")");
                        f.push(" ");
                    } else if (i === 7) {
                        f.push("-");
                    } else if (i === 9) {
                        f.push("-");
                    }
                    f.push(l);
                } else if (s[0] === "9") {
                    // 9 ...
                    this.phoneMaxlength = 18;
                    if (i === 0) {
                        f.push("+");
                        f.push("7");
                        f.push(" ");
                        f.push("(");
                    } else if (i === 4) {
                        f.push(")");
                        f.push(" ");
                    } else if (i === 7) {
                        f.push("-");
                    } else if (i === 9) {
                        f.push("-");
                    }
                    f.push(l);
                } else {
                    // [0-6] ...
                    this.phoneMaxlength = 24;
                    if (i === 0) {
                        f.push("+");
                    } else if (i === 3) {
                        f.push(" ");
                    }
                    f.push(l);
                }
            }
            const p = f.join("");
            if (p === this.form.phone) return;
            this.form.phone = p;
        },

    },

    created() {
        this.initWidget();
    },

    mounted() {
        swiper = new Swiper(".widget__list", {
            slidesPerView: 1,
            spaceBetween: 0,
            direction: "horizontal",
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper__btn-next',
                prevEl: '.swiper__btn-prev',
            },
            //centeredSlides: true,
            //loop: true,
            allowTouchMove: false,
            freeMode: true,
            //speed: 500,
            freeModeMomentum: false,

            breakpoints: {
                500: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                750: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },

                1000: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4.5,
                    spaceBetween: 20,
                },
            },
        });
    },
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.swiper {
    height: 360px;
}

.widget {

    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    &__text {
        margin: 30px 0;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & input {
            border-radius: 20px;
            max-width: 225px;
            width: 100%;
            font-size: 16px;
            padding: 0 16px;
            height: 52px;
            outline: none;
            color: #000;
            background-color: transparent;
            border: 1px solid #000;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            @media only screen and (min-width: 420px) {
                max-width: 318px;
            }

            @media only screen and (min-width: 600px) {
                max-width: 426px;
            }
        }

        p {
            margin: 0 0 15px 0;
        }
    }

    .modal-close {
        position: absolute;
        right: -8px;
        top: -8px;
        padding: 10px;
        background: transparent;
        cursor: pointer;
        margin: 0 auto;
        display: block;
        border: none;
        outline: none;
        @media only screen and (min-width: 420px) {
            right: 0;
            top: -7px;
        }
        @media only screen and (min-width: 600px) {
            right: 10px;
            top: 10px;
        }
    }

    .modal-submit {
        border-radius: 20px;
        cursor: pointer;
        background-color: #000;
        text-align: center;
        font-size: 13px;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        padding: 18px 44px;
        font-weight: 700;
        transition: 0.2s;
        display: inline-block;
        border: none;
        outline: none;
        width: 100%;
    }

    .modal-mask {
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        max-width: 280px;
        width: 100%;
        margin: 0 auto;
        padding: 25px 15px;
        background-color: #fff;
        transition: all 0.3s ease;
        position: relative;
        border-radius: 20px;

        @media only screen and (min-width: 420px) {
            max-width: 350px;
            padding: 30px;
        }
        @media only screen and (min-width: 600px) {
            max-width: 460px;
            padding: 50px;
        }
    }

    .modal-header {
        margin: 0 0 30px 0;
        text-align: center;

    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }


    .swiper-wrapper {
        margin: 15px 0;
    }


    &__item.swiper-slide {
        background: #f7f7f7;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .14);
        border-radius: 20px;
        position: relative;
        height: 280px;
        cursor: pointer;
        transition: all .2s linear;
    }

    &__item {
        transition: all .2s linear;

        &:hover {
            .widget__btns {
                transition: all .2s linear;
                height: 80px;
                box-shadow: 0 7px 5px rgba(0, 0, 0, .06), 0 4px 6px rgba(0, 0, 0, .1);
            }
        }
    }

    &__item-wrap {
        padding: 20px;
        height: 240px;
    }

    &__img {
        position: absolute;
        right: 0;
        bottom: 75px;
        max-height: 140px;
    }

    &__item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__top {
        text-align: left;
    }

    &__model {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 10px;
    }

    &__descr {
        font-size: 12px;
        line-height: 120%;
    }

    &__bottom .tag-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        max-width: 60%;

        & .tag {
            display: inline-block;
            background-color: #fff;
            border: 1px solid #ed1c24;
            padding: 5px 4px;
            color: #ed1c24;
            border-radius: 20px;
            line-height: 100%;
            font-size: 11px;
            text-align: center;
            margin-bottom: 5px;
        }

        & .tag--white {
            border-color: #fff;
            color: #333;
        }
    }

    &__bottom-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .car-vin {
        text-transform: uppercase;
        font-size: 12px;
        color: #333;
    }

    &__btns {
        height: 0;
        transition: all .2s linear;
        z-index: 2;
        overflow: hidden;
        position: absolute;
        top: calc(100% - 20px);
        left: 0;
        width: 100%;
        box-sizing: border-box;
        background: #f7f7f7;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .14);
        border-radius: 0 0 2.4rem 2.4rem;
        padding: 0 1.5rem;
    }

    &__btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        background-color: #ed1c24;
        color: #fff;
        padding: 12px 25px;
        margin: 20px 0 10px 0;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        border: 1px solid transparent;
        box-shadow: none;
        appearance: none;
        transition: all .2s linear;
        cursor: pointer;
        user-select: none;
        outline: none;
        text-transform: uppercase;
    }

    & .swiper__btns {
        display: flex;
        justify-content: flex-end;
    }

    & .swiper__btn-next,
    & .swiper__btn-prev {
        cursor: pointer;
        width: 36px;
        height: 36px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .icon {
            height: 10px;
            width: 10px;
        }
    }

    & .swiper__btn-prev {
        margin-right: 10px;
    }

    &.service-mazda {
        background-color: #191919;
        color: #fff !important;
        font-family: Mazda Type Cyrillic, sans-serif !important;

        & .swiper__btn-prev,
        & .swiper__btn-next {
            background-color: #fff !important;
        }

        .widget {
            &__item {
                color: #000;
            }
        }

        .white-section {
            background-color: #191919;
        }

        & .modal-header,
        & form p {
            color: #000;
        }


    }

    &.kia {
        font-family: KiaSignature, sans-serif !important;
		.widget {
			&__btn {
				border-radius: 0;
				background: #000 !important;
				color: #ffffff;
				border: 2px solid #000;
				padding: 10px 10px;
				font-size: 10px;
				transition: all 0.3s ease-in;

				span {
					position: relative;
				}

				span:after {
					position: absolute;
					content: "";
					bottom: -4px;
					width: 100%;
					left: 0;
					right: 0;
					border-bottom: 1px solid;
					transform: scaleX(0);
					transform-origin: left center;
					transition: transform .25s ease-out;
					will-change: transform;
					pointer-events: none;
				}
				span:hover:after {
					transform: scaleX(1);
				}
			}
		}
		.modal-submit {
			border-radius: 0;
			transition: all 0.3s ease-in;

			span {
				position: relative;
			}

			span:after {
				position: absolute;
				content: "";
				bottom: -4px;
				width: 100%;
				left: 0;
				right: 0;
				border-bottom: 1px solid;
				transform: scaleX(0);
				transform-origin: left center;
				transition: transform .25s ease-out;
				will-change: transform;
				pointer-events: none;
			}
			span:hover:after {
				transform: scaleX(1);
			}
		}
		.steps-banner__pic-elem {
			path {
				stroke: #000000;
			}
			path[fill-rule="evenodd"] {
				fill: #000000;
			}
			circle {
				stroke: #000000;
			}
			path[d="M53.4375 12H24.4375C23.8852 12 23.4375 12.4477 23.4375 13V34.2383L38.8447 45.0152C38.9124 45.0625 39.0022 45.0634 39.0708 45.0174L54.4375 34.7031V13C54.4375 12.4477 53.9898 12 53.4375 12Z"] {
				fill: #ffffff;
			}
		}
    }

    &.ford {
        font-family: Source Sans Pro, sans-serif !important;
    }

    &.hyundai {
        font-family: HuindaySansHead, sans-serif !important;
    }

    &.geely {
        font-family: "NeoSansCyr", Helvetica, sans-serif !important;
    }

    &.iamuaz,
    &.iamhyundai,
    &.iamford {
        font-family: "Source Sans Pro", Helvetica, Arial, sans-serif !important;
    }

    &.haval {
        font-family: "Haval", Helvetica, Arial, sans-serif !important;
        .widget {
            &__btn {
                border-radius: 0;
                background: #000 !important;
                color: #ffffff;
                border: 2px solid #000;
                padding: 10px 20px;
                font-size: 11px;
                transition: all 0.3s ease-in;
                &:hover {
                    border: 2px solid #000;
                    color: #000;
                    background: #fff !important;
                }
            }
        }
        .modal-container,
        input,
        .modal-submit {
            border-radius: 0;
        }
		.steps-banner__pic-elem {
			path {
				stroke: #000000;
			}
			path[fill-rule="evenodd"] {
				fill: #000000;
			}
			circle {
				stroke: #000000;
			}
			path[d="M53.4375 12H24.4375C23.8852 12 23.4375 12.4477 23.4375 13V34.2383L38.8447 45.0152C38.9124 45.0625 39.0022 45.0634 39.0708 45.0174L54.4375 34.7031V13C54.4375 12.4477 53.9898 12 53.4375 12Z"] {
				fill: #ffffff;
			}
		}
    }

    &.iamkia {
        font-family: "KIA", Helvetica, Arial, sans-serif !important;
    }

    &.iamfaw {
        font-family: "Electra Text", Helvetica, Arial, sans-serif !important;
    }

    &.iammoskvich {
        font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    }
}

.white-section {
    //margin: 0 -7rem 4rem;
    background-color: #fff;
    padding: 20px 30px 50px;
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 3px 14px rgba(17, 19, 28, .1);
    border-radius: 20px;
}

.heading-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    //min-height: 4rem;
}

.steps-banner {
    display: flex;

    @media (max-width: 900px) {
        display: block;
    }
}

.steps-banner__left {
    width: calc(50% + 25px);
    background-image: url('~@/assets/bg-left.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 35px 70px 35px 55px;
    display: flex;
    align-items: center;
    margin-right: -45px;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        padding: 35px 70px 35px 35px;
    }

    @media (max-width: 900px) {
        background-image: url('~@/assets/bg-top-xs.svg');
        display: block;
        width: 300px;
        padding: 25px 15px;
        min-height: 240px;
        margin: 0 auto;
    }
}

.steps-banner__right {
    width: calc(50% + 25px);
    background-image: url('~@/assets/bg-right.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 35px 70px 35px 130px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        padding: 35px 70px 35px 100px;
    }

    @media (max-width: 900px) {
        background-image: url('~@/assets/bg-bottom-xs.svg');
        display: block;
        width: 300px;
        padding: 65px 40px 25px;
        min-height: 260px;
        margin: -48px auto 0;
    }
}

.steps-banner__pic {
    width: 135px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 30px;

    @media (max-width: 1100px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 900px) {
        width: 80px;
        height: 80px;
        margin: 0 auto 15px;
    }
}

.steps-banner__text {
    text-align: left;
    @media (max-width: 900px) {
        text-align: center;
    }
}

.steps-banner__text-top {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #1a1a1a;
    margin-bottom: 7px;
}

.steps-banner__text-bot {
    color: #666;
    font-size: 14px;
    line-height: 160%;
}


</style>
